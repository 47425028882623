// -- CUSTOM -- //

$maxWidthMob: 450px;
$maxWidthDesk: 1170px;

$min-width-mob: 768px;
$min-width-tablet: 1024px;
$min-width-desk: 1200px;
$min-width-xl: 1600px;


// -- BTN -- //
@mixin btnSvg($width:string, $height:string, $stroke-width:string, $space: string, $color:string) {
  width: $width;
  height: $height;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;

  a, > span {
    position: absolute;
    color: $color;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: DINCondBoldAlternate, AdiHausPSCon-Bd, sans-serif;
    font-size: 16px;
    line-height: $height;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: block;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;

    span {
      background: $color;
      display: inline-block;
      position: absolute;
      -webkit-transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;

      &.top,
      &.bottom {
        width: calc(#{$width} / 2 - #{$space});
        height: $stroke-width;
      }

      &.left,
      &.right {
        height: $height;
        width: $stroke-width;
      }

      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
      }
      &.right {
        right: 0;
      }

      &.top_left,
      &.bottom_center {
        left: 0;
      }

      &.top_center,
      &.bottom_right {
        left: calc(#{$width} / 2 + #{$space});
        //left: calc(100% / 2 + 10px);
      }

      &.top_right {
        left: $width;
        width: calc(#{$width} / 2 + #{$space});
        //width: calc(100% / 2 + 10px);
      }

      &.bottom_left {
        left: calc(-#{$width} / 2 - #{$space});
        width: calc(#{$width} / 2 + #{$space});
        //left: calc(-100% / 2 - 10px);
        //width: calc(100% / 2 + 10px);
      }

      &.right_top,
      &.left_bottom {
        top: 0;
      }

      &.left_top {
        top: calc(-#{$height} * 2);
      }

      &.right_bottom {
        top: calc(#{$height} * 2);
      }
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.4);

    > div span {
      &.top {
        -webkit-transform: translateX(calc(-#{$width} / 2 - #{$space}));
        transform: translateX(calc(-#{$width} / 2 - #{$space}));
      }

      &.bottom {
        -webkit-transform: translateX(calc(#{$width} / 2 + #{$space}));
        transform: translateX(calc(#{$width} / 2 + #{$space}));
      }

      &.left {
        -webkit-transform: translateY(calc(#{$height} * 2));
        transform: translateY(calc(#{$height} * 2));
      }

      &.right {
        -webkit-transform: translateY(calc(#{-$height} * 2));
        transform: translateY(calc(#{-$height} * 2));
      }
    }
  }
}

//animation hover btn arrow overlay
@keyframes hover-arrow {
  0% {background-position: center; }
  50% {background-position: left 13px center; }
  100% {background-position: center; }
}
@-webkit-keyframes hover-arrow {
  0% {background-position: center; }
  50% {background-position: left 13px center; }
  100% {background-position: center; }
}


////-----////

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

/* cross browser opacity */
@mixin cross-opacity($val) {
  /* cross browser opacity */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$val*100})";
  filter: alpha(opacity=($val*100));
  -moz-opacity: $val;
  -khtml-opacity: $val;
  opacity: $val;
  /* end */
}

/* image backgrounds relative to img/ with random param */
@mixin img-bg($url, $position:"center", $cover:false, $repeat:false) {
  background-image: url('../img/#{$url}?ver=1.0.0');
  @if $position {
    background-position: #{$position};
  }
  @if $cover {
    background-size: #{$cover};
  }
  @if $repeat {
  } @else {
    background-repeat: no-repeat;
  }
}

/* image backgrounds relative to img/ with random param */
@mixin cross-transition($property:all, $duration:.2s, $easing:linear) {
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
  transition: $property $duration $easing;
}

/* image backgrounds relative to img/ with random param */
@mixin cross-transition-delay($property:all, $duration:.2s, $easing:linear, $delay:.2s) {
  -webkit-transition: $property $duration $easing $delay;
  -moz-transition: $property $duration $easing $delay;
  transition: $property $duration $easing $delay;
}

/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// Shorthand mixin for absolute positioning
/// Serves as an alias for `position(absolute, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin absolute($args...) {
  @include position(absolute, $args...);
}



@import "mixins";

html, body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'BrandonGrotesque-Medium', sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}
strong, h1, h2, h3, h4 {
  font-family: 'BrandonGrotesque-Bold', sans-serif;
}

h1, h2, h3, h4, p, ul, li {
  cursor: default;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1.2em;
}

// body.disableScroll::-webkit-scrollbar{
//   pointer-events: none;
// }

a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

ol, ul {
  list-style: none;
  padding-left: 0;
}

/* FIREFOX DOTTED LINE FIX */
a, a:active, a:focus, a:hover {
  outline: none;
}

object, embed {
  outline: 0;
}

input::-moz-focus-inner {
  border: 0;
}

.cont_body{
  background-color: #fff;
  width: 100%;
  opacity: 0;
  &.show{
    opacity: 1;
  }
}

.container{
  width: 100%;
  max-width: $maxWidthMob;
  margin: auto;
  opacity: 0;
  background-color: #fff;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: $min-width-mob) {
    width: 70%; //73.125%;
    max-width: $maxWidthDesk;
  }

  @media only screen and (min-width: $min-width-tablet) {
    width: 60%; //73.125%;
    max-width: $maxWidthDesk;
  }
}

.red{
  color: #e2211c !important;
}

/* --FINE GENERAL-- */

/* LOADER */
#loading {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 123px;
  transform: translate(-50%, -50%);
  @include cross-transition(opacity, 0.3s);
  opacity: 0;
  
  > img{
    position: absolute;
    width: 100%;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
  }

  &.show{
    opacity: 1;
    // animation: showLoading 1s linear;
    // animation-iteration-count: 1;
  }
}

// @keyframes showLoading {
//   0% { opacity: 0; }
//   70% { opacity: 0; }
//   100% { opacity: 1; }
// }

// @-webkit-keyframes showLoading {
//   0% { opacity: 0; }
//   70% { opacity: 0; }
//   100% { opacity: 1; }
// }

@keyframes pulsate {
  0% {transform: scale(0.9, 0.9); opacity: 1;}
  50% {transform: scale(1.1, 1.1); opacity: 1;}
  100% {transform: scale(0.9, 0.9); opacity: 1;}
}

@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.9, 0.9); opacity: 0.7;}
  50% {-webkit-transform: scale(1.2, 1.2); opacity: 0.7;}
  100% {-webkit-transform: scale(0.9, 0.9); opacity: 0.7;}
}


header {
  padding: 25px 0px 20px 0px;
  text-align: center;
  display: flex;
  justify-content: center;

  .cont_header{
    position: relative;
  }

  img{
    &.italy{
      min-width: 68px;
      max-width: 131px;
      width: 18.13%;
      position: absolute;
      left: 5.86%;
    }
    &.logo{
      min-width: 90px;
      max-width: 258px;
      width: 24%;
    }
  }

  @media only screen and (min-width: $min-width-mob) {
    padding: 40px 0 30px 0;
    img{
      &.italy{
        width: 11.19%;
        left: 9.14%;
      }
      &.logo{
        width: 22%;
      }
    }
  }

  @media only screen and (min-width: $min-width-tablet) {
    padding: 50px 0 35px 0;
  }

  @media only screen and (min-width: $min-width-desk) {
    padding: 60px 0 40px 0;
  }
}

#slider_visual{
  // width: 100%;
  margin-bottom: 35px;

  .swiper-wrapper{
    align-items: stretch;
  }
  .swiper-slide{
    text-align: center;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
      width: 106%;
      margin-left: -3%;

      &.img_chips{
        max-width: 84.6%;
      }
    }
  }

  @media only screen and (min-width: $min-width-mob) {
    margin-bottom: 50px;

    // .swiper-slide{
      // img{
        // max-width: 88.5%;
        // max-width: 106%;

        // &.img_chips{
        //   max-width: 84.6%;
        // }
      // }
    // }
  }

  @media only screen and (min-width: $min-width-tablet) {
    margin-bottom: 65px;
  }

  @media only screen and (min-width: $min-width-desk) {
    margin-bottom: 80px;
  }
}

.content_text{
  text-align: center;
  padding-bottom: 40px;

  h1{
    margin-bottom: 15px;
    span{
      display: none;
    }
    img{
      width: 50%;
      min-width: 188px;
      max-width: 540px;
    }
  }

  .italy{
    width: 23.2%;
    min-width: 87px;
    max-width: 250px;
  }

  h2 {
    font-size: 19px;
    // font-weight: 600;
    font-family: 'BrandonGrotesque-Bold', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }

  p{
    width: 76.8%;
    max-width: 800px;
    margin: auto;
    font-size: 15px;
    line-height: 1.2em;

    strong{
      color: #009544;
    }
  }

  .donna{
    width: 29.6%;
    min-width: 111px;
    max-width: 320px;
    margin-bottom: 10px;
  }

  .wavy{
    width: 90%;
    margin-top: 5px;
  }

  @media only screen and (min-width: $min-width-mob) {
    padding-bottom: 60px;

    h1{
      margin-bottom: 25px;
      img{
        width: 46.15%;
      }
    }
  
    h2 {
      font-size: 26px;
      margin-bottom: 24px;
    }
  
    p{
      font-size: 20px;
    }
    
    .italy{
      width: 21.37%;
      margin-bottom: 5px;
    }

    .donna{
      width: 27.35%;
      margin-bottom: 20px;
    }

    .wavy{
      width: 50%;
      max-width: 600px;
    }
  }

  @media only screen and (min-width: $min-width-tablet) {
    padding-bottom: 70px;

    h1{
      margin-bottom: 32px;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 26px;
    }
  
    p{
      font-size: 22px;
    }

    .italy{
      margin-bottom: 10px;
    }

    .donna{
      margin-bottom: 25px;
    }
  }

  @media only screen and (min-width: $min-width-desk) {
    padding-bottom: 80px;

    h1{
      margin-bottom: 34px;
    }

    h2 {
      font-size: 34px;
      margin-bottom: 28px;
    }
  
    p{
      font-size: 24px;
    }

    .donna{
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-width: $min-width-xl) {
    padding-bottom: 90px;

    h1{
      margin-bottom: 40px;
    }

    h2 {
      font-size: 38px;
      margin-bottom: 30px;
    }
  
    p{
      font-size: 26px;
    }

    .donna{
      margin-bottom: 35px;
    }
  }
}

#slider_foto {
  width: calc(100% - 20px);
  margin: auto;
  padding-bottom: 40px;

  .swiper-slide{
    text-align: center;
    
    img{
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (min-width: $min-width-mob) {
    width: calc(100% - 40px);
    padding-bottom: 60px;
  }

  @media only screen and (min-width: $min-width-tablet) {
    width: calc(100% - 60px);
    padding-bottom: 80px;
  }

  @media only screen and (min-width: $min-width-desk) {
    padding-bottom: 100px;
  }

  @media only screen and (min-width: $min-width-xl) {
    padding-bottom: 120px;
  }
}

#slider_chips {
  text-align: center;

  h2 {
    font-size: 19px;
    text-align: center;
    margin-bottom: 20px;

    @media only screen and (min-width: $min-width-mob) {
      font-size: 28px;
      margin-bottom: 40px;
      br {
        display: none;
      }
    }
  
    @media only screen and (min-width: $min-width-tablet) {
      font-size: 30px;
      margin-bottom: 50px;
    }

    @media only screen and (min-width: $min-width-desk) {
      font-size: 34px;
      margin-bottom: 60px;
    }

    @media only screen and (min-width: $min-width-xl) {
      font-size: 38px;
      margin-bottom: 70px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    left: 50%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    outline: none;

    &:after{
      display: none;
    }

    @media only screen and (min-width: $min-width-mob) {
      width: 60px;
    }
  
    @media only screen and (min-width: $min-width-tablet) {
      width: 70px;
    }

    @media only screen and (min-width: $min-width-desk) {
      width: 80px;
    }
  }

  .swiper-button-prev{
    background-image: url('../img/freccia_previous.png');
    margin-left: calc(-18.8% - 40px);

    @media only screen and (min-width: $min-width-mob) {
      margin-left: calc(-10% - 60px);
    }
    @media only screen and (min-width: $min-width-tablet) {
      margin-left: calc(-10% - 70px);
    }
    @media only screen and (min-width: $min-width-desk) {
      margin-left: calc(-10% - 80px);
    }
  }
  .swiper-button-next{
    background-image: url('../img/freccia_next.png');
    margin-left: 18.8%;

    @media only screen and (min-width: $min-width-mob) {
      margin-left: 10%;
    }
  }

  .swiper-slide{
    img{
      width: 37.6%;
      min-width: 141px; 

      @media only screen and (min-width: $min-width-mob) {
        width: 21.3%;
      }
    }
  }

  .cont_chips{
    position: relative;
    max-width: 750px;
    width: 90%;
    margin: 20px auto;
    // padding-bottom: 40%;
    min-height: 180px;

    .chips{
      position: absolute;
      display: flex;
      align-items: flex-start;
      opacity: 0;
      @include cross-transition(opacity, 0.3s);

      &.show{
        position: relative;
        opacity: 1;
      }

      &.chips0{
        opacity: 1;
      }

      img{
        width: 36.6%;
      }

      > div{
        margin: 0 3%;
        text-align: left;
        
        h3{
          margin-bottom: 2px;
          font-size: 17px;
        }

        p{
          font-size: 15px;
        }
      }
    }

    @media only screen and (min-width: $min-width-mob) {
      width: 68%;
      min-width: 450px;
      margin: 60px auto 40px auto;
      padding-bottom: 30%;
      min-height: 0;

      .chips{
        img{
          width: 36.8%;
        }
        > div{
          h3{
            margin-bottom: 5px;
            font-size: 22px;
          }

          p{
            font-size: 18px;
          }
        }
      }
    }

    @media only screen and (min-width: $min-width-tablet) {
      margin: 70px auto 50px auto;
      min-width: 620px;
      padding-bottom: 28%;

      .chips{
        > div{
          h3{
            margin-bottom: 6px;
            font-size: 26px;
          }

          p{
            font-size: 22px;
          }
        }
      }
    }

    @media only screen and (min-width: $min-width-desk) {
      margin: 80px auto 60px auto;
      min-width: 690px;
      padding-bottom: 26%;

      .chips{
        > div{
          h3{
            margin-bottom: 8px;
            font-size: 28px;
          }

          p{
            font-size: 28px;
          }
        }
      }
    }

    @media only screen and (min-width: $min-width-xl) {
      margin: 90px auto 70px auto;
      min-width: 730px;

      .chips{
        > div{
          h3{
            margin-bottom: 10px;
            font-size: 31px;
          }

          p{
            font-size: 26px;
          }
        }
      }
    }
  }
}

#footer{
  text-align: center;
  padding-bottom: 35px;

  .discover_more{
    width: 46.9%;
    min-width: 176px;
    max-width: 314px;
    margin-bottom: 12px;
  }

  ul{
    margin-bottom: 5px;
    li{
      display: inline;
      margin: 0 8px;
      img{
        width: 9.6%;
        min-width: 36px;
        max-width: 65px;
      }
    }
  }

  .mail {
    font-size: 19px;
    // font-weight: 600;
    font-family: 'BrandonGrotesque-Bold', sans-serif;
    color: #ec4949;
  }

  a:hover{
    opacity: 0.7;
    @include cross-transition(opacity, 0.3s);
  }

  @media only screen and (min-width: $min-width-mob) {
    padding-bottom: 60px;

    .discover_more{
      width: 26.8%;
      margin-bottom: 15px;
    }

    ul{
      margin-bottom: 10px;
      li{
        margin: 0 10px;
        img{
          width: 5.5%;
        }
      }
    }

    .mail {
      font-size: 22px;
    }
  }

  @media only screen and (min-width: $min-width-tablet) {
    padding-bottom: 90px;

    .discover_more{
      margin-bottom: 18px;
    }

    ul{
      margin-bottom: 12px;
      li{
        margin: 0 10px;
      }
    }

    .mail {
      font-size: 25px;
    }
  }

  @media only screen and (min-width: $min-width-desk) {
    padding-bottom: 100px;

    .mail {
      font-size: 28px;
    }
  }

  @media only screen and (min-width: $min-width-xl) {
    padding-bottom: 110px;

    .discover_more{
      margin-bottom: 20px;
    }

    ul{
      margin-bottom: 15px;
      li{
        margin: 0 12px;
      }
    }

    .mail {
      font-size: 31px;
    }
  }
}


// COOKIEBOT
.cont_cookie{
  width: 70%;
  margin: 0px auto 50px auto;
}
#footer{
  #btn_cookie_policy{
    margin-top: 30px;
    display: inline-block;
    font-size: 12px;
    color: #000;

    &:hover{
      text-decoration: underline;
    }
  }
}
#CybotCookiebotDialog{
  #CybotCookiebotDialogPoweredbyLink{
    display: none;
  }

  a, 
  div, 
  #CybotCookiebotDialogBodyContentControls, 
  #CybotCookiebotDialogBodyContentTitle {
    font-family: 'BrandonGrotesque-Medium', sans-serif !important;
  }

  #CybotCookiebotDialogBodyContent{
    padding: 20px;
  }

  #CybotCookiebotDialogBodyButtons{
    padding-left: 0;
  }

  .CookieDeclaration{
    display: none;
  }
}

#cookie_modal{
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  .bg {
    position: fixed; /* Stay in place */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(3, 1, 4); /* Fallback color */
    background-color: rgba(3, 1, 4, 0.65); /* Black w/ opacity */
  }

  .btn-close{
    position: absolute;
    top: 5%;
    right: 5%;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 27px;
    z-index: 2;
    cursor: pointer;
    background-color: #000;
    border-radius: 50% 50% 50% 50%;
    color: #fff;
    @include cross-transition(color, 0.2s, linear);

    &:hover{
      background-color: #e2211c;
    }
  }

  .modal-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: 70%;
    min-width: 300px;
    max-height: 80%;
    overflow: hidden;
    padding: 40px 30px 30px 30px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    flex-direction: column;
    background: #fff;

    .content-scroll{
      overflow-y: auto;
      max-height: 500px;
    }
  }
}