// -- CUSTOM -- //

$maxWidthMob: 450px;
$maxWidthDesk: 1170px;

$min-width-mob: 768px;
$min-width-tablet: 1024px;
$min-width-desk: 1200px;
$min-width-xl: 1600px;


// -- BTN -- //
@mixin btnSvg($width:string, $height:string, $stroke-width:string, $space: string, $color:string) {
  width: $width;
  height: $height;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;

  a, > span {
    position: absolute;
    color: $color;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: DINCondBoldAlternate, AdiHausPSCon-Bd, sans-serif;
    font-size: 16px;
    line-height: $height;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: block;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height;

    span {
      background: $color;
      display: inline-block;
      position: absolute;
      -webkit-transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;

      &.top,
      &.bottom {
        width: calc(#{$width} / 2 - #{$space});
        height: $stroke-width;
      }

      &.left,
      &.right {
        height: $height;
        width: $stroke-width;
      }

      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
      }
      &.right {
        right: 0;
      }

      &.top_left,
      &.bottom_center {
        left: 0;
      }

      &.top_center,
      &.bottom_right {
        left: calc(#{$width} / 2 + #{$space});
        //left: calc(100% / 2 + 10px);
      }

      &.top_right {
        left: $width;
        width: calc(#{$width} / 2 + #{$space});
        //width: calc(100% / 2 + 10px);
      }

      &.bottom_left {
        left: calc(-#{$width} / 2 - #{$space});
        width: calc(#{$width} / 2 + #{$space});
        //left: calc(-100% / 2 - 10px);
        //width: calc(100% / 2 + 10px);
      }

      &.right_top,
      &.left_bottom {
        top: 0;
      }

      &.left_top {
        top: calc(-#{$height} * 2);
      }

      &.right_bottom {
        top: calc(#{$height} * 2);
      }
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.4);

    > div span {
      &.top {
        -webkit-transform: translateX(calc(-#{$width} / 2 - #{$space}));
        transform: translateX(calc(-#{$width} / 2 - #{$space}));
      }

      &.bottom {
        -webkit-transform: translateX(calc(#{$width} / 2 + #{$space}));
        transform: translateX(calc(#{$width} / 2 + #{$space}));
      }

      &.left {
        -webkit-transform: translateY(calc(#{$height} * 2));
        transform: translateY(calc(#{$height} * 2));
      }

      &.right {
        -webkit-transform: translateY(calc(#{-$height} * 2));
        transform: translateY(calc(#{-$height} * 2));
      }
    }
  }
}

//animation hover btn arrow overlay
@keyframes hover-arrow {
  0% {background-position: center; }
  50% {background-position: left 13px center; }
  100% {background-position: center; }
}
@-webkit-keyframes hover-arrow {
  0% {background-position: center; }
  50% {background-position: left 13px center; }
  100% {background-position: center; }
}


////-----////

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

/* cross browser opacity */
@mixin cross-opacity($val) {
  /* cross browser opacity */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$val*100})";
  filter: alpha(opacity=($val*100));
  -moz-opacity: $val;
  -khtml-opacity: $val;
  opacity: $val;
  /* end */
}

/* image backgrounds relative to img/ with random param */
@mixin img-bg($url, $position:"center", $cover:false, $repeat:false) {
  background-image: url('../img/#{$url}?ver=1.0.0');
  @if $position {
    background-position: #{$position};
  }
  @if $cover {
    background-size: #{$cover};
  }
  @if $repeat {
  } @else {
    background-repeat: no-repeat;
  }
}

/* image backgrounds relative to img/ with random param */
@mixin cross-transition($property:all, $duration:.2s, $easing:linear) {
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
  transition: $property $duration $easing;
}

/* image backgrounds relative to img/ with random param */
@mixin cross-transition-delay($property:all, $duration:.2s, $easing:linear, $delay:.2s) {
  -webkit-transition: $property $duration $easing $delay;
  -moz-transition: $property $duration $easing $delay;
  transition: $property $duration $easing $delay;
}

/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// Shorthand mixin for absolute positioning
/// Serves as an alias for `position(absolute, ...)`
/// @param {Arglist} $args - Offsets
/// @require {mixin} position
@mixin absolute($args...) {
  @include position(absolute, $args...);
}


